import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Working with you to get our teachers paid!</h1>
            </header>
            <div className="content">
                <p>Now accepting donations</p>
                <ul className="actions">
                    <li>{/*<a href="#one" className="button next scrolly">Get Started</a>*/}
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
<input type="hidden" name="cmd" value="_s-xclick" />
<input type="hidden" name="hosted_button_id" value="QK7B8BWS7CVLG" />
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
</form>

                    </li>
                </ul>
            </div>
        </div>
    </section>
)

export default Banner
